@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
  margin: 0;
  padding: 0;
  font-family: Karla, sans-serif;
  width: 100%;
  height: 100%;
}

div {
  font-family: Karla, sans-serif;
}

p {
  margin: 0;
  padding: 0;
}

#root {
  width: 100%;
  height: 100%;
}
