@import '../node_modules/tailwindcss/base.css';
@import '../node_modules/tailwindcss/components.css';

html {
  font-size: 'clamp(18px, calc(1.125rem + ((1vw - 3.75px) * 0.6623)), 24px)';
}

input[type='date']::-webkit-calendar-picker-indicator {
  background: transparent;
  bottom: 0;
  color: transparent;
  cursor: pointer;
  height: auto;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: auto;
}

input[type='date'] {
  border-radius: 5px;
}

.dateIcon {
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto 0;
  right: 10px;
  color: black;
  width: 1rem;
  height: 1rem;
  z-index: 2;
  pointer-events: none;
}

button.BaseButton {
  background: #467eff;
  border-color: #467eff;
  border-style: none;
  color: #ffffff;
  border-radius: 4px;
  border-width: 1px;
  font-size: 14px;
  line-height: normal;
  cursor: pointer;
  padding-left: 15px;
  padding-right: 15px;
  min-width: 100px;
  height: 30px;
  white-space: nowrap;
  overflow-x: hidden;
  text-overflow: ellipsis;
}

button.BaseButton:focus {
  background: #3148b2;
}

button.BaseButton:hover {
  background: #467eff99;
}

.agreementregister-disabled .AgreementDetails-saveChangedAgreementButton {
  display: none;
}

.agreementregister-disabled .AgreementChanges-confirmDeleteButton {
  display: none;
}

.agreementregister-disabled .AgreementChanges-saveButton {
  display: none;
}

.agreementregister-disabled
  .AgreementDetails-actions
  .AgreementDetails-deleteAgreementButton {
  display: none;
}
.agreementregister-disabled .AgreementsTab-accordionTabHeader button {
  display: none;
}

.agreementregister-disabled .AgreementChanges .p-panel-header button,
.agreementregister-disabled .AgreementChanges .p-datatable-wrapper tbody tr td:last-child button,
.agreementregister-disabled .AgreementChanges .p-datatable-wrapper tbody tr td:last-child .p-row-toggler,
.agreementregister-disabled .PvLiensControlRenderer-buttons {
  visibility: hidden;
}

.agreementregister-disabled .p-dropdown,
.agreementregister-disabled .p-inputswitch,
.agreementregister-disabled input {
  pointer-events: none;
}



.popoverWidth {
  width: 200px;
}
.document-note {
  div {
    border-bottom: 1px solid #b4b4b4;
  }
  div:last-of-type {
    border: none;
  }
}